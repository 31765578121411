import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseTaxon } from 'src/app/types';
import { Image } from 'src/app/types/image';

@Injectable({ providedIn: 'root' })
export class LightboxService {
  private list = new BehaviorSubject<Image[]>([]);
  private openedIndex = new BehaviorSubject<number>(-1);

  private takson?: BaseTaxon;
  get currentTakson(): BaseTaxon | undefined {
    return this.takson;
  }

  get imageList(): Observable<Image[]> {
    return this.list.asObservable();
  }

  get currentIndex(): Observable<number> {
    return this.openedIndex.asObservable();
  }

  public registerImage(image: Image): void {
    const list = this.list.value;
    this.list.next([...list, image]);
  }

  public unregisterImage(image: Image): void {
    const list = this.list.value;
    const idx = list.findIndex((e) => e.src === image.src);
    this.list.next(list.filter((e, i) => i !== idx));
  }

  public open(image: Image): void {
    const list = this.list.value;
    let idx = 0;
    if (list.length > 0) {
      idx = list.findIndex((e) => e.src == image.src);
      if (idx === -1) {
        this.list.next([...list, image]);
        idx = list.length;
      }
    } else {
      this.list.next([image]);
    }

    this.openedIndex.next(idx);
  }

  public close(): void {
    this.openedIndex.next(-1);
  }

  public next(): void {
    let idx = this.openedIndex.value;
    const length = this.list.value.length;
    if (idx === length - 1) {
      idx = 0;
    } else {
      idx++;
    }
    this.openedIndex.next(idx);
  }

  public prev(): void {
    let idx = this.openedIndex.value;
    const length = this.list.value.length;
    if (idx === 0) {
      idx = length - 1;
    } else {
      idx--;
    }
    this.openedIndex.next(idx);
  }

  public setTakson(takson: BaseTaxon): void {
    this.takson = takson;
  }
}
