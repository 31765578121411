<div *ngIf="indexOfShowingImage > -1">
  <button mat-icon-button class="close" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="content" #content>
    <button *ngIf="imageList.length > 1" mat-icon-button class="left-arrow" (click)="onBack()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="image">
      <figure class="caption" *ngIf="imageList[indexOfShowingImage] as image">
        <img #img [src]="image.src | urlStorage" />
        <app-marker-layer
          *ngIf="image.markers && image.taxons"
          [width]="img.offsetWidth"
          [height]="img.offsetHeight"
          [scale]="scale"
          [markers]="image.markers"
          [taxons]="image.taxons"
        ></app-marker-layer>
        <figcaption [style.width.px]="widthOfImage">
          <div>
            <p [matTooltip]="caption.tooltip || ''">{{ caption.date }}</p>
            <p>©&nbsp;{{ caption.author }}</p>
          </div>
          <p>
            <span *ngFor="let i of caption.description">
              <a *ngIf="i.startsWith('#')" [routerLink]="'/tags' | urlLang" [queryParams]="{ _app: 'main', tag: i.slice(1)} "> {{i}} </a>
              <ng-container *ngIf="!i.startsWith('#')">{{i}}</ng-container>
            </span>
          </p>
        </figcaption>
      </figure>
    </div>
    <button *ngIf="imageList.length > 1" mat-icon-button class="right-arrow" (click)="onNext()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
