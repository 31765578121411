import ShapeConfig from '../ShapeConfig.type';

const SHAPES = {
  first: {
    tip: 100,
    tipHandle: [15, 80],
    middle: [
      {
        point: [25, 40],
        handleNext: [25, 15],
        handlePrev: [25, 55]
      }
    ],
    baseHandle: [20, 0]
  },
  second: {
    tip: 50,
    tipHandle: [15, 80],
    middle: [
      {
        point: [25, 40],
        handleNext: [25, 15],
        handlePrev: [25, 55]
      }
    ],
    baseHandle: [20, 0]
  },
  third: {
    tip: 80,
    tipHandle: [15, 80],
    middle: [
      {
        point: [25, 40],
        handleNext: [25, 15],
        handlePrev: [25, 55]
      }
    ],
    baseHandle: [20, 20]
  }
};

export default {
  ...ShapeConfig,
  name: 'flower.petal_shape',
  selectType: {
    ...ShapeConfig.selectType,
    values: Object.values(SHAPES),
    renderValue: (): string => ''
  },
  constraints: [{ name: 'groups.use', values: ['flowering'] }],
  renderName: (): string => 'features.flower.petal_shape.'
};
