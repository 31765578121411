import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider, forwardRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { KeycloakService, KeycloakOptions } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';

import { ApiInterceptor } from './api.interceptor';
import { getDefaultLang } from './helpers/lang';

export function createTranslateLoader(dir: string): (http: HttpClient) => MultiTranslateHttpLoader {
  return function (http: HttpClient): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(http, [
      { prefix: '../assets/i18n/', suffix: '.json' },
      { prefix: `../assets/i18n/${dir}/`, suffix: '.json' }
    ]);
  };
}

export function appInitializerFactory(translate: TranslateService) {
  return (): Promise<string> => {
    const lang = getDefaultLang();
    translate.setDefaultLang(lang);
    return firstValueFrom(translate.use(lang));
  };
}

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true
};

export function keycloakInitializer(app: 'main' | 'admin' | 'user') {
  return function (keycloak: KeycloakService): () => Promise<boolean> {
    const options: KeycloakOptions = environment.keycloakConfig as KeycloakOptions;

    // add app specific suffix to client Id
    if ((options?.config as KeycloakConfig).clientId) {
      (options.config as KeycloakConfig).clientId = (options.config as KeycloakConfig).clientId + app;
    }
    return (): Promise<boolean> => keycloak.init(options);
  };
}
