import { Component, EventEmitter, HostBinding, Input, Output, ElementRef, HostListener } from '@angular/core';
import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';

import { BaseTaxon } from 'src/app/types';
import { Marker } from 'src/app/types/image';

@Component({
  selector: 'app-markers-form',
  templateUrl: './markers-form.component.html',
  styleUrls: ['./markers-form.component.scss']
})
export class MarksersFormComponent {
  @Input() markers: Marker[] = [];
  @Input() taxons: BaseTaxon[] = [];
  @Input() selectedTaxon?: string;

  @Input() scale = 1;

  @Output() updatedMarkers: EventEmitter<Marker[]> = new EventEmitter<Marker[]>();
  @Output() exit = new EventEmitter<boolean>();

  @HostBinding('style.width.px') @Input() width?: number;
  @HostBinding('style.height.px') @Input() height?: number;

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target) && !this.first) {
      this.exit.emit(true);
    }
    this.first = false;
  }

  first = true;

  constructor(private eRef: ElementRef) {}

  getTaxonName(hovered?: string): string {
    return hovered ? this.taxons.find((t) => t.taxonId === hovered)?.getFullName() || '---' : '';
  }
  getTaxonIdx(taxonId?: string): number {
    return taxonId ? this.taxons.findIndex((t) => t.taxonId === taxonId) + 1 : 0;
  }

  deleteMarker(index: number, event: Event): void {
    this.updatedMarkers.emit(this.markers.filter((_, idx) => idx !== index));
    event.stopPropagation();
  }

  moveStopped(index: number, event: CdkDragEnd): void {
    const m = this.markers[index];
    const { x, y } = event.distance;
    m.positionX += x / this.scale;
    m.positionY += y / this.scale;
    event.source.reset();
    this.updatedMarkers.emit(this.markers);
  }

  resizeStopped(index: number, event: CdkDragMove<unknown>): void {
    const m = this.markers[index];
    m.radius += event.delta.x / this.scale;
    event.source.reset();
    this.updatedMarkers.emit(this.markers);
  }

  addMarker(event: MouseEvent): void {
    const { offsetX, offsetY } = event;
    const newMarker = new Marker(
      {
        markerId: undefined,
        taxonId: this.selectedTaxon,
        positionX: offsetX / this.scale,
        positionY: offsetY / this.scale,
        radius: 100
      },
      this.scale
    );
    this.updatedMarkers.emit([...this.markers, newMarker]);
  }
}
