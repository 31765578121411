<div class="markers">
  <ng-container *ngFor="let m of markers; let idx = index">
    <div
      *ngIf="m.radius && m.positionX"
      [style.width.px]="m.radius * scale * 2"
      [style.height.px]="m.radius * scale * 2"
      [style.top.px]="m.positionY * scale - m.radius * scale"
      [style.left.px]="m.positionX * scale - m.radius * scale"
      [style.border-radius.px]="m.radius * scale"
      [class.hovered]="m.taxonId === hovered"
      (mouseenter)="hovered = m.taxonId"
      (mouseleave)="hovered = undefined"
    >
      <span [ngClass]="{ point: true, active: !!hovered }" [matTooltip]="getTaxonName(hovered)">
        {{m.taxonId ? getTaxonIdx(m.taxonId) : ''}}
      </span>
    </div>
  </ng-container>
  <mat-chip-list>
    <mat-chip *ngFor="let t of taxons" (click)="hovered = t.taxonId" [selected]="hovered === t.taxonId">
      {{ t?.getFullName() || 'undef' }}
    </mat-chip>
  </mat-chip-list>
</div>
