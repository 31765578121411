import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { User, ErrorMessage, Geolocation } from 'src/app/types';
import date from 'src/app/helpers/date';

import { AuthService } from 'src/app/services/auth.service';
import { IFile, ImageService, IUpload } from 'src/app/services/database/image.service';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent {
  @Input() uploadCallback!: (data: {
    src: string;
    thumb: string;
    date: string;
    location?: Geolocation;
    width: number;
    height: number;
  }) => Promise<number>;

  @Output() image = new EventEmitter<number>();

  loading = false;
  errorMessages: ErrorMessage[] | null = null;
  croppedImage: any = '';

  file?: IUpload;
  originalFile?: IFile;

  imageToCrop?: IFile;
  user$: Observable<User | null>;

  constructor(private authService: AuthService, private imageService: ImageService) {
    this.user$ = this.authService.user;
  }

  onSelect(event: EventTarget | null): void {
    if (!event) {
      return;
    }

    const target = event as HTMLInputElement;

    if (!target.files) {
      return;
    }
    const f = target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(f);
    reader.onload = async () => {
      this.file = await this.imageService.prepareImage(reader.result as ArrayBuffer, f);
      this.originalFile = { ...this.file.file };
    };
  }

  onRemove(): void {
    this.file = undefined;
  }
  setCropped(base64File: string): void {
    this.imageService
      .getImageThumb(base64File)
      .then(({ data, thumb }) => {
        if (!this.file) {
          return;
        }
        this.file.file.data = data;
        this.file.file.thumb = thumb;
        this.imageToCrop = undefined;
      })
      .catch((e) => console.error('TODO error', e));
  }

  onSubmit(): void {
    if (!this.file) {
      return;
    }
    this.loading = true;
    this.file.uploading = true;
  }

  finishUpload({ url, thumb, width, height }: { url: string; thumb: string; width: number; height: number }, item: IUpload): void {
    this.uploadCallback({
      src: url,
      thumb,
      date: date(item.file.lastModified).toISOString(),
      location: item.file.location,
      width,
      height
    })
      .then((imageId: number) => {
        item.uploading = false;
        this.loading = false;
        this.image.emit(imageId);
      })
      .catch((err) => {
        console.error('error', err);
        this.errorMessages = [{ msg: 'api.' + String(err.code) } as ErrorMessage];
      });
  }
}
