import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, debounceTime, filter, firstValueFrom, Observable, switchMap } from 'rxjs';
import { isNonNull } from 'src/app/helpers/assertions';
import { getDefaultLang } from 'src/app/helpers/lang';
import { SearchTaxon } from 'src/app/types';
import { Feature, Features } from 'src/app/types/features';
import { APISearchService, APITaxonService } from 'src/app/api/services';
import { CollectionTaxon } from 'src/app/types/taxon';
import { APIFeatureGroup, APIFeatureName } from 'src/app/api/models';

@Injectable({ providedIn: 'root' })
export class SearchService {
  searchString = new BehaviorSubject<string>('');
  filter = new BehaviorSubject<Features | undefined>(undefined);

  detailed = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private taxonService: APITaxonService,
    private searchService: APISearchService
  ) {
    this.route.queryParams.subscribe((params) => {
      // exclude _gu param from options
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { search, tab, _gu, ...options } = params;

      let newFeatures;
      if (search) {
        newFeatures = new Features(
          Object.keys(options).map((k) => {
            const [group, name] = k.split('.');
            return { group, name, value: options[k] };
          })
        );
      } else {
        newFeatures = new Features([]);
      }
      this.filter.next(newFeatures);
    });
  }

  doSearch(search: string): void {
    if (!this.filter.value) {
      return;
    }
    this.detailed.next(false);
    this.searchString.next(search);
    this.router
      .navigate([getDefaultLang(), 'search'], {
        queryParams: {
          _app: 'main',
          search,
          ...this.filter.value.queryfy()
        }
      })
      .catch((e) => console.error('TODO error', e));
  }

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setFilter(groupName: string, values: any): void {
    const [group, name] = groupName.split('.');
    const feature = Feature.create(group as APIFeatureGroup, name as APIFeatureName, values);
    const currentFilter = this.filter.value;
    if (currentFilter) {
      currentFilter.setFeature([feature], groupName);
      this.filter.next(currentFilter);
    }
  }

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public search(start: BehaviorSubject<string | null>, options: any): Observable<{ txt: string; units: SearchTaxon[] }> {
    return start.pipe(
      debounceTime(200),
      filter(isNonNull),
      switchMap(async (startText: string) => {
        if (startText.length < 4) {
          return { txt: startText, units: [] };
        }
        return { txt: startText, units: await this.findByNameFragment(startText, options) };
      })
    );
  }

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async findByNameFragment(text: string, options: any): Promise<SearchTaxon[]> {
    // just to skip eslint unused vars error
    options.toString();
    // const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
    const names = await firstValueFrom(this.taxonService.findTaxonByNameFragment({ text }));
    return names.taxons.map((t) => new SearchTaxon(t));
  }

  public async findChildren(taxonId: string, ownership: ('plants' | 'favourites' | 'shopping')[]): Promise<CollectionTaxon[]> {
    const names = await firstValueFrom(this.taxonService.findTaxonsByParentAction({ taxonId, 'ownership[]': ownership }));
    return names.taxons.map((t) => new CollectionTaxon(t));
  }

  public async searchByLocalName(text: string): Promise<{ data: SearchTaxon[]; total: number }> {
    const resp = await firstValueFrom(this.searchService.searchTaxonByLocalName({ text }));
    return { data: resp.taxons.map((t) => new SearchTaxon(t)), total: resp.total };
  }

  public async searchByLatinName(text: string, withAll: boolean): Promise<{ data: SearchTaxon[]; total: number }> {
    const resp = await firstValueFrom(this.searchService.searchTaxonByLatinName({ text, withAll }));
    return { data: resp.taxons.map((t) => new SearchTaxon(t)), total: resp.total };
  }

  public async searchByVariety(text: string): Promise<{ data: SearchTaxon[]; total: number }> {
    const resp = await firstValueFrom(this.searchService.searchTaxonByVariety({ text }));
    return { data: resp.taxons.map((t) => new SearchTaxon(t)), total: resp.total };
  }
}
