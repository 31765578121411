import { Point, Shape } from '../types/features/selectTypes';

const getPath = (points: Point[]): string => {
  if (points.length < 2) {
    return '';
  }
  const [tip, ...middles] = points;
  const base = middles.pop();
  if (!base) {
    return '';
  }

  if (tip.handleNext && tip.handlePrev && base.handleNext && base.handlePrev) {
    if (points.length === 2) {
      return `M ${tip.point[0]} ${tip.point[1]} 
      C ${tip.handleNext[0]} ${tip.handleNext[1]}, ${base.handlePrev[0]} ${base.handlePrev[1]}, ${base.point[0]} ${base.point[1]}`;
    } else {
      return `M ${tip.point[0]} ${tip.point[1]} C ${tip.handleNext[0]} ${tip.handleNext[1]},
      ${middles
        .map((p) =>
          p.handlePrev && p.handleNext
            ? `${p.handlePrev[0]} ${p.handlePrev[1]}, ${p.point[0]} ${p.point[1]} C ${p.handleNext[0]} ${p.handleNext[1]}, `
            : ''
        )
        .join(' ')}
      ${base.handlePrev[0]} ${base.handlePrev[1]}, ${base.point[0]} ${base.point[1]}`;
    }
  } else {
    return '';
  }
};

const r = (val: number, factor: number) => val * factor;

export function getShape(val: Shape, size: number, mirror = false): string {
  // half of canvas size - the X axis
  const half = size / 2;
  // factor to calcukate size
  const f = size / 100;
  // mirror factor
  const m = mirror ? -1 : 1;

  // calculated points of the path
  const points = [];
  points.push({
    point: [half, size - r(val.tip, f)],
    handleNext: [half - m * r(val.tipHandle[0], f), size - r(val.tipHandle[1], f)]
  } as Point);

  points.push(
    ...val.middle.map(
      (p) =>
        ({
          point: [half - m * r(p.point[0], f), size - r(p.point[1], f)],
          ...(p.handleNext ? { handleNext: [half - m * r(p.handleNext[0], f), size - r(p.handleNext[1], f)] } : {}),
          ...(p.handlePrev ? { handlePrev: [half - m * r(p.handlePrev[0], f), size - r(p.handlePrev[1], f)] } : {})
        } as Point)
    )
  );

  points.push({
    point: [half, size],
    handlePrev: [half - m * r(val.baseHandle[0], f), size - r(val.baseHandle[1], f)]
  } as Point);

  return getPath(points);
}
