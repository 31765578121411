import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent {
  @Output() croppedImage = new EventEmitter();

  @Input() image!: string;
  @Input() aspectRatio = 16 / 9;
  @Input() resizeToWidth!: number;
  @Input() maintainAspectRatio = true;

  imageTransform: { flipH?: boolean; flipV?: boolean } = {};
  rotation = 0;

  cropped?: string | null;

  imageCropped(event: ImageCroppedEvent): void {
    this.cropped = event.base64;
  }

  imageLoaded(): void {
    return;
  }
  cropperReady(): void {
    return;
  }
  loadImageFailed(err: unknown): void {
    console.error('err', err);
  }

  flip(horizontal: boolean): void {
    const flip = horizontal
      ? { flipH: this.imageTransform.flipH ? !this.imageTransform.flipH : true }
      : { flipV: this.imageTransform.flipV ? !this.imageTransform.flipV : true };
    this.imageTransform = { ...this.imageTransform, ...flip };
  }

  rotate(clockwise: boolean): void {
    const right = this.rotation === 3 ? 0 : this.rotation + 1;
    const left = this.rotation === 0 ? 3 : this.rotation - 1;
    this.rotation = clockwise ? right : left;
  }

  save(): void {
    this.croppedImage.emit(this.cropped);
  }
}
