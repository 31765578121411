<div class="markers">
  <div class="overlay" (click)="addMarker($event)"></div>
  <ng-container *ngFor="let m of markers; let idx = index">
    <ng-container *ngIf="m.radius && m.positionX && selectedTaxon === m.taxonId">
      <div
        [style.width.px]="m.radius * scale * 2"
        [style.height.px]="m.radius * scale * 2"
        [style.top.px]="m.positionY * scale - m.radius * scale"
        [style.left.px]="m.positionX * scale - m.radius * scale"
        [style.border-radius.px]="m.radius * scale"
        class="active"
        cdkDrag
        cdkDragBoundary=".markers"
        (cdkDragEnded)="moveStopped(idx, $event)"
      >
        <span class="point" cdkDragHandle> {{m.taxonId ? getTaxonIdx(m.taxonId) : ''}} </span>
        <span class="point resize" cdkDrag (cdkDragMoved)="resizeStopped(idx, $event)"></span>
        <app-icon (click)="deleteMarker(idx, $event)" [matTooltip]="'composition.add.buttons.delete' | translate">
          delete_forever
        </app-icon>
      </div>
    </ng-container>
    <ng-container *ngIf="m.radius && m.positionX && selectedTaxon !== m.taxonId">
      <div
        *ngIf="m.radius && m.positionX"
        [style.width.px]="m.radius * scale * 2"
        [style.height.px]="m.radius * scale * 2"
        [style.top.px]="m.positionY * scale - m.radius * scale"
        [style.left.px]="m.positionX * scale - m.radius * scale"
        [style.border-radius.px]="m.radius * scale"
      >
        <span class="point" cdkDragHandle> {{m.taxonId ? getTaxonIdx(m.taxonId) : ''}} </span>
      </div>
    </ng-container>
  </ng-container>
  <mat-chip-list>
    <mat-chip *ngFor="let t of taxons" [selected]="selectedTaxon === t.taxonId"> {{ t?.getFullName() || 'undef' }} </mat-chip>
  </mat-chip-list>
</div>
