import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { getDefaultLang } from 'src/app/helpers/lang';
import { DateTime } from 'luxon';
import { LightboxService } from 'src/app/services/utilities/lightbox.service';
import { Subscription } from 'rxjs';
import { Image as _Image } from 'src/app/types';
import { TagService } from 'src/app/services/database/tag.service';
import { Marker } from 'src/app/types/image';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit, OnDestroy {
  type: 'composition' | 'image' | undefined;
  imageList: _Image[] = [];

  indexOfShowingImage = -1;

  listSubscription?: Subscription;
  idxSubscription?: Subscription;

  // composition variables
  selectedImageTaxons?: Marker[];
  imageToShow?: HTMLImageElement;
  width = 750;
  height?: number;

  scale = 1;
  markerLayerPosition: { x: number; y: number } = { x: 0, y: 0 };

  caption: {
    date?: string;
    tooltip?: string;
    author?: string;
    description?: string[];
  } = {};

  @Output() closed = new EventEmitter();

  @ViewChild('img') img?: ElementRef;
  widthOfImage?: number;
  heightOfImage?: number;

  constructor(public lightboxService: LightboxService, private tagService: TagService) {}

  ngOnInit(): void {
    this.listSubscription = this.lightboxService.imageList.subscribe((list) => {
      this.imageList = list;
    });

    this.idxSubscription = this.lightboxService.currentIndex.subscribe((idx) => {
      this.indexOfShowingImage = idx;
      if (idx > -1) {
        this.selectedImage();
      }
    });
  }

  ngOnDestroy(): void {
    this.idxSubscription?.unsubscribe();
    this.listSubscription?.unsubscribe();
  }

  onNext(): void {
    this.lightboxService.next();
  }

  onBack(): void {
    this.lightboxService.prev();
  }

  onClose(): void {
    this.lightboxService.close();
  }

  selectedImage(): void {
    if (this.indexOfShowingImage == -1) {
      return;
    }

    const image = this.imageList[this.indexOfShowingImage];

    this.selectedImageTaxons = image.markers;
    this.imageToShow = new Image();
    this.imageToShow.src = image.src;
    this.imageToShow.onload = (event: Event) => {
      const loaded = event.currentTarget as HTMLImageElement;

      if (!loaded) {
        return;
      }

      // this.height = (loaded.height * this.width) / loaded.width;
      const native = this.img?.nativeElement;
      this.scale = native.width / loaded.width;

      this.widthOfImage = native.width;
      this.heightOfImage = native.height;
      this.markerLayerPosition = { x: native.offsetLeft, y: native.offsetTop };
    };

    this.getInfo(image.date ? new Date(image.date) : new Date(), image.author.name || image.author.toString(), image.description ?? '');
  }

  getInfo(date: Date, author: string, description: string): void {
    let lang: string;
    if (getDefaultLang() === 'pl') {
      lang = 'pl-PL';
    } else {
      lang = 'en-GB';
    }
    const localDate = new Intl.DateTimeFormat(lang, { month: 'long' }).format(date);
    this.caption = {
      date: localDate.charAt(0).toUpperCase() + localDate.slice(1),
      tooltip: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
      author,
      description: this.tagService.splitDescription(description)
    };
  }

  @HostListener('window:resize')
  changeScale(): void {
    if (this.img) {
      this.widthOfImage = this.img.nativeElement.width;
      this.scale = this.img.nativeElement.width / this.width;
    }
  }
}
