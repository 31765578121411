/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { APIBaseTaxon } from '../models/api-base-taxon';
import { APICollectionTaxon } from '../models/api-collection-taxon';
import { APIDetailedTaxon } from '../models/api-detailed-taxon';
import { APIFeature } from '../models/api-feature';
import { APIPhoto } from '../models/api-photo';
import { APISearchTaxon } from '../models/api-search-taxon';

@Injectable({
  providedIn: 'root'
})
export class APITaxonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation addVarietyToTaxon
   */
  static readonly AddVarietyToTaxonPath = '/taxon/variety';

  /**
   * Adds new variety to a given taxon ID (parent or sibling)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addVarietyToTaxon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addVarietyToTaxon$Response(params: {
    /**
     * Taxon data to be added
     */
    body: { taxonId: string; variety: string; brand?: string; pbr?: boolean; registered?: boolean };
  }): Observable<StrictHttpResponse<{ taxon: APIBaseTaxon }>> {
    const rb = new RequestBuilder(this.rootUrl, APITaxonService.AddVarietyToTaxonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxon: APIBaseTaxon }>;
        })
      );
  }

  /**
   * Adds new variety to a given taxon ID (parent or sibling)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addVarietyToTaxon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addVarietyToTaxon(params: {
    /**
     * Taxon data to be added
     */
    body: { taxonId: string; variety: string; brand?: string; pbr?: boolean; registered?: boolean };
  }): Observable<{ taxon: APIBaseTaxon }> {
    return this.addVarietyToTaxon$Response(params).pipe(
      map((r: StrictHttpResponse<{ taxon: APIBaseTaxon }>) => r.body as { taxon: APIBaseTaxon })
    );
  }

  /**
   * Path part for operation findTaxonByNameFragment
   */
  static readonly FindTaxonByNameFragmentPath = '/taxon/find';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTaxonByNameFragment()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTaxonByNameFragment$Response(params: {
    /**
     * Text to find in names
     */
    text: string;
  }): Observable<StrictHttpResponse<{ taxons: Array<APISearchTaxon> }>> {
    const rb = new RequestBuilder(this.rootUrl, APITaxonService.FindTaxonByNameFragmentPath, 'get');
    if (params) {
      rb.query('text', params.text, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxons: Array<APISearchTaxon> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findTaxonByNameFragment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTaxonByNameFragment(params: {
    /**
     * Text to find in names
     */
    text: string;
  }): Observable<{ taxons: Array<APISearchTaxon> }> {
    return this.findTaxonByNameFragment$Response(params).pipe(
      map((r: StrictHttpResponse<{ taxons: Array<APISearchTaxon> }>) => r.body as { taxons: Array<APISearchTaxon> })
    );
  }

  /**
   * Path part for operation findTaxonsByParentAction
   */
  static readonly FindTaxonsByParentActionPath = '/taxon/find/varieties/{taxonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTaxonsByParentAction()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTaxonsByParentAction$Response(params: {
    /**
     * Taxon parent Id
     */
    taxonId: string;

    /**
     * Which ownership should be included to BaseTaxon info
     */
    'ownership[]'?: Array<'plants' | 'favourites' | 'shopping'>;
  }): Observable<StrictHttpResponse<{ taxons: Array<APICollectionTaxon> }>> {
    const rb = new RequestBuilder(this.rootUrl, APITaxonService.FindTaxonsByParentActionPath, 'get');
    if (params) {
      rb.path('taxonId', params.taxonId, {});
      rb.query('ownership[]', params['ownership[]'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxons: Array<APICollectionTaxon> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findTaxonsByParentAction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTaxonsByParentAction(params: {
    /**
     * Taxon parent Id
     */
    taxonId: string;

    /**
     * Which ownership should be included to BaseTaxon info
     */
    'ownership[]'?: Array<'plants' | 'favourites' | 'shopping'>;
  }): Observable<{ taxons: Array<APICollectionTaxon> }> {
    return this.findTaxonsByParentAction$Response(params).pipe(
      map((r: StrictHttpResponse<{ taxons: Array<APICollectionTaxon> }>) => r.body as { taxons: Array<APICollectionTaxon> })
    );
  }

  /**
   * Path part for operation getTaxonDetailsBySpecies
   */
  static readonly GetTaxonDetailsBySpeciesPath = '/taxon/details/{latinName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxonDetailsBySpecies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonDetailsBySpecies$Response(params: {
    /**
     * Latin name of the plant
     */
    latinName: string;
  }): Observable<StrictHttpResponse<{ taxon: APIDetailedTaxon }>> {
    const rb = new RequestBuilder(this.rootUrl, APITaxonService.GetTaxonDetailsBySpeciesPath, 'get');
    if (params) {
      rb.path('latinName', params.latinName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxon: APIDetailedTaxon }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaxonDetailsBySpecies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonDetailsBySpecies(params: {
    /**
     * Latin name of the plant
     */
    latinName: string;
  }): Observable<{ taxon: APIDetailedTaxon }> {
    return this.getTaxonDetailsBySpecies$Response(params).pipe(
      map((r: StrictHttpResponse<{ taxon: APIDetailedTaxon }>) => r.body as { taxon: APIDetailedTaxon })
    );
  }

  /**
   * Path part for operation getTaxonDetailsByVariety
   */
  static readonly GetTaxonDetailsByVarietyPath = '/taxon/details/{latinName}/{variety}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxonDetailsByVariety()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonDetailsByVariety$Response(params: {
    /**
     * Latin name of the plant
     */
    latinName: string;

    /**
     * Variety of the plant
     */
    variety: string;
  }): Observable<StrictHttpResponse<{ taxon: APIDetailedTaxon }>> {
    const rb = new RequestBuilder(this.rootUrl, APITaxonService.GetTaxonDetailsByVarietyPath, 'get');
    if (params) {
      rb.path('latinName', params.latinName, {});
      rb.path('variety', params.variety, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxon: APIDetailedTaxon }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaxonDetailsByVariety$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonDetailsByVariety(params: {
    /**
     * Latin name of the plant
     */
    latinName: string;

    /**
     * Variety of the plant
     */
    variety: string;
  }): Observable<{ taxon: APIDetailedTaxon }> {
    return this.getTaxonDetailsByVariety$Response(params).pipe(
      map((r: StrictHttpResponse<{ taxon: APIDetailedTaxon }>) => r.body as { taxon: APIDetailedTaxon })
    );
  }

  /**
   * Path part for operation getTaxonFeatures
   */
  static readonly GetTaxonFeaturesPath = '/taxon/details/{taxonId}/features';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxonFeatures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonFeatures$Response(params: {
    /**
     * Taxon parent Id
     */
    taxonId: string;
  }): Observable<StrictHttpResponse<{ features: Array<APIFeature> }>> {
    const rb = new RequestBuilder(this.rootUrl, APITaxonService.GetTaxonFeaturesPath, 'get');
    if (params) {
      rb.path('taxonId', params.taxonId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ features: Array<APIFeature> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaxonFeatures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonFeatures(params: {
    /**
     * Taxon parent Id
     */
    taxonId: string;
  }): Observable<{ features: Array<APIFeature> }> {
    return this.getTaxonFeatures$Response(params).pipe(
      map((r: StrictHttpResponse<{ features: Array<APIFeature> }>) => r.body as { features: Array<APIFeature> })
    );
  }

  /**
   * Path part for operation getTaxonImages
   */
  static readonly GetTaxonImagesPath = '/taxon/details/{taxonId}/images';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxonImages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonImages$Response(params: {
    /**
     * Taxon parent Id
     */
    taxonId: string;
  }): Observable<StrictHttpResponse<{ photos: Array<APIPhoto> }>> {
    const rb = new RequestBuilder(this.rootUrl, APITaxonService.GetTaxonImagesPath, 'get');
    if (params) {
      rb.path('taxonId', params.taxonId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ photos: Array<APIPhoto> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaxonImages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonImages(params: {
    /**
     * Taxon parent Id
     */
    taxonId: string;
  }): Observable<{ photos: Array<APIPhoto> }> {
    return this.getTaxonImages$Response(params).pipe(
      map((r: StrictHttpResponse<{ photos: Array<APIPhoto> }>) => r.body as { photos: Array<APIPhoto> })
    );
  }

  /**
   * Path part for operation getTaxonVarieties
   */
  static readonly GetTaxonVarietiesPath = '/taxon/details/{taxonId}/varieties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaxonVarieties()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonVarieties$Response(params: {
    /**
     * Taxon parent Id
     */
    taxonId: string;
  }): Observable<StrictHttpResponse<{ taxons: Array<APICollectionTaxon> }>> {
    const rb = new RequestBuilder(this.rootUrl, APITaxonService.GetTaxonVarietiesPath, 'get');
    if (params) {
      rb.path('taxonId', params.taxonId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json'
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<{ taxons: Array<APICollectionTaxon> }>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaxonVarieties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaxonVarieties(params: {
    /**
     * Taxon parent Id
     */
    taxonId: string;
  }): Observable<{ taxons: Array<APICollectionTaxon> }> {
    return this.getTaxonVarieties$Response(params).pipe(
      map((r: StrictHttpResponse<{ taxons: Array<APICollectionTaxon> }>) => r.body as { taxons: Array<APICollectionTaxon> })
    );
  }
}
