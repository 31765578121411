<div class="formButtons">
  <mat-icon (click)="flip(true)" [matTooltip]="'forms.image.crop.flip' | translate" matTooltipPosition="above"> flip </mat-icon>
  <mat-icon (click)="flip(false)" class="horizontal-flip" [matTooltip]="'forms.image.crop.flip-ver' | translate" matTooltipPosition="above">
    flip
  </mat-icon>
  <mat-icon (click)="rotate(true)" [matTooltip]="'forms.image.crop.rotate' | translate" matTooltipPosition="above"> rotate_right </mat-icon>
  <mat-icon (click)="rotate(false)" [matTooltip]="'forms.image.crop.rotate' | translate" matTooltipPosition="above"> rotate_left </mat-icon>
</div>
<image-cropper
  [imageBase64]="image"
  [maintainAspectRatio]="maintainAspectRatio"
  [aspectRatio]="aspectRatio"
  [resizeToWidth]="resizeToWidth"
  [transform]="imageTransform"
  (imageCropped)="imageCropped($event)"
  [canvasRotation]="rotation"
  (imageLoaded)="imageLoaded()"
  (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed($event)"
></image-cropper>

<app-action-button type="accept" variant="filled" (click)="save()"> {{ 'forms.image.crop.save' | translate }} </app-action-button>
