import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

import { CookieService } from 'ngx-cookie-service';
import { UserIdleModule } from 'angular-user-idle';

import { AppRoutingModule } from './app-routing.module';
import { AppRootComponent } from './app-root.component';
import { AppUserComponent } from './app.component';
import { UserComponentsModule } from './components/components.module';

import { environment } from 'src/environments/environment';

import { CustomMaterialModule } from 'src/app/components/custom.material.module';

import { ComponentsModule } from 'src/app/components/components.module';
import { UNIQUE_ID } from 'src/app/services/utilities/external.service';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { DirectivesModule } from 'src/app/directives/directives.module';

import { ApiModule } from 'src/app/api/api.module';
import { ApiInterceptor } from 'src/app/api.interceptor';
import { GlobalErrorHandler } from 'src/app/error.handler';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {
  createTranslateLoader,
  keycloakInitializer,
  appInitializerFactory,
  API_INTERCEPTOR_PROVIDER
} from 'src/app/app.module.initializer';

import { AppLangModule } from 'src/app/app.module';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

registerLocaleData(localePl, 'pl-PL');

@NgModule({
  declarations: [AppUserComponent, AppRootComponent],
  imports: [
    ApiModule.forRoot({ rootUrl: environment.newApiUrl }),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    CustomMaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader('user'),
        deps: [HttpClient]
      }
    }),
    UserComponentsModule,
    PipeModule,
    DirectivesModule,
    ComponentsModule,
    AppLangModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    KeycloakAngularModule,
    MatMomentDateModule,
    UserIdleModule.forRoot({ idle: 1800, timeout: 1, ping: 120 })
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    CookieService,
    {
      provide: UNIQUE_ID,
      useValue: 'user'
    },
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer('user'),
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ],
  bootstrap: [AppRootComponent]
})
export class AppModule {}
