<mat-toolbar>
  <button class="back" mat-icon-button (click)="back()" [attr.aria-label]="'aria.go-back' | translate">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>

  <span class="spacer"></span>

  <!-- TODO PHASE 2 
  <a
    [routerLink]="'/notifications' | urlLang"
    mat-icon-button
    class="notification"
    [attr.aria-label]="'aria.user-notifications-page' | translate"
  >
    <mat-icon>notifications</mat-icon>
  </a> -->
  <button mat-icon-button [matMenuTriggerFor]="menu" [attr.aria-label]="'aria.user-settings-menu' | translate">
    <mat-icon>settings</mat-icon>
  </button>

  <button mat-icon-button (click)="toggleDrawer.emit(true)" [attr.aria-label]="'aria.main-application.menu' | translate">
    <mat-icon>menu</mat-icon>
  </button>
  <a class="dashboard" [routerLink]="'/' | urlLang">
    <img src="/assets/images/fav.png" width="64" height="64" />
  </a>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <a mat-menu-item [routerLink]="'/settings' | urlLang" [queryParams]="{ _app: 'user' }">
      <mat-icon>settings</mat-icon>
      {{ 'navigation.settings' | translate }}
    </a>
    <a mat-menu-item (click)="logout()">
      <mat-icon>power_settings_new</mat-icon>
      {{ 'navigation.logout' | translate }}
    </a>
  </ng-template>
</mat-menu>
