import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AppLangComponent } from 'src/app/app-lang.component';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/httpService';
import { LanguageService } from 'src/app/services/language/language.service';
import { SnackbarService } from 'src/app/services/utilities/snackbar.service';
import { User } from 'src/app/types';

@Component({
  selector: 'app-user',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppUserComponent extends AppLangComponent {
  authUserActive = 'active';
  user$: Observable<User | null>;
  loading = false;

  constructor(
    translate: LanguageService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private http: HttpService
  ) {
    super(translate, route, router);
    this.user$ = this.authService.user;
  }

  onResend(): void {
    this.loading = true;
    this.http
      .post('auth/activate/resend', {})
      .then(() => {
        this.snackbarService.showSnackbar('forms.activate.resend.success', 'success');
        this.loading = false;
      })
      .catch(() => {
        this.snackbarService.showSnackbar('forms.activate.resend.error', 'warning');
        this.loading = false;
      });
  }
}
