import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: 'action-button.component.html',
  styleUrls: ['action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {
  @Input() type: 'action' | 'accept' | 'deny' | 'cancel' | '' = '';
  @Input() disabled = false;
  @Input() variant: 'filled' | 'iconed' | '' = '';
  @Input() loading = false;
  @Input() customIcon?: string;
  @Input() noIcon = false;
  @Input() size: 'small' | 'normal' | 'large' = 'normal';
  @Input() routerLink?: string;
  @Input() queryParams?: { [key: string]: string | number };
  @Input() progress?: number;

  @Output() activated = new EventEmitter<boolean>();

  className?: string;
  icon: string | null = null;

  ngOnInit(): void {
    this.className = this.type + ' ' + this.variant + ' ' + this.size;
    this.icon = this.type === 'accept' ? 'check' : this.type === 'deny' ? 'close' : this.type === 'cancel' ? 'arrow_back' : null;
    if (this.customIcon) {
      this.icon = this.customIcon;
    }
    if (this.noIcon) {
      this.icon = null;
    }
  }
}
