import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-navigation-bar',
  templateUrl: 'navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent {
  @Output() toggleDrawer = new EventEmitter<boolean>();

  constructor(private authService: AuthService, private router: Router, private location: Location) {}

  logout(): void {
    this.authService.logout();
    const currentUrl = this.router.url;

    // force check router guard
    void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([currentUrl]));
  }

  back(): void {
    this.location.back();
  }
}
