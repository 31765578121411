<mat-drawer-container class="drawer-container" hasBackdrop>
  <mat-drawer #drawer mode="over" position="end">
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/' | urlLang" (click)="drawer.toggle()"> {{ 'sidebar.navigation.home' | translate }} </a>
      <a mat-list-item [routerLink]="'/collection' | urlLang" (click)="drawer.toggle()">
        {{ 'sidebar.navigation.collections' | translate }}
      </a>
      <a mat-list-item [routerLink]="'/identify' | urlLang" (click)="drawer.toggle()"> {{ 'sidebar.navigation.identify' | translate }} </a>
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="'/' | urlLang" [queryParams]="{_app:'main'}"> {{ 'sidebar.navigation.catalog' | translate }} </a>
    </mat-nav-list>
  </mat-drawer>

  <app-user-navigation-bar (toggleDrawer)="drawer.toggle()"></app-user-navigation-bar>

  <router-outlet></router-outlet>
</mat-drawer-container>
<app-lightbox></app-lightbox>
