import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

import { BaseTaxon } from 'src/app/types';
import { Marker } from 'src/app/types/image';

@Component({
  selector: 'app-marker-layer',
  templateUrl: './marker-layer.component.html',
  styleUrls: ['./marker-layer.component.scss']
})
export class MarkerLayerComponent implements OnChanges {
  @Input() markers: Marker[] = [];
  @Input() taxons: BaseTaxon[] = [];
  @Input() selectedTaxon?: string;

  @Input() scale = 1;

  hovered?: string;

  @HostBinding('style.width.px') @Input() width?: number;
  @HostBinding('style.height.px') @Input() height?: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTaxon) {
      this.hovered = changes.selectedTaxon.currentValue;
    }
  }

  getTaxonName(hovered?: string): string {
    return hovered ? this.taxons.find((t) => t.taxonId === hovered)?.getFullName() || '---' : '';
  }
  getTaxonIdx(taxonId?: string): number {
    return taxonId ? this.taxons.findIndex((t) => t.taxonId === taxonId) + 1 : 0;
  }
}
