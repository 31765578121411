import { APICollection } from 'src/app/api/models';
import { ImageSet } from './image';

export class Collection implements APICollection {
  collectionId?: string;
  name?: string;
  description?: string;
  total?: number;

  photoSets?: ImageSet[];

  // links for navigation
  pageLink: string;
  addPlantLink: string;
  addPhotoLink: string;

  constructor(data: APICollection) {
    this.collectionId = data.collectionId;
    this.name = data.name;
    this.description = data.description;
    this.total = data.total;

    this.photoSets = data.photoSets?.map((is) => new ImageSet(is, this.collectionId));
    // this.owner = new User(data.owner);

    // if (data.buckets && Array.isArray(data.buckets)) {
    //   this.buckets = data.buckets.map((a: any) => new Bucket(a));
    // }

    this.pageLink = this.collectionId ? `/collection/custom/${this.collectionId}` : '/collection/default';
    this.addPlantLink = this.collectionId ? `/collection/custom/${this.collectionId}/item` : '/collection/item';
    this.addPhotoLink = this.collectionId ? `/photos/${this.collectionId}/new` : '/photos/new';
  }
}
