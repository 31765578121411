import { Feature } from '.';
import { FeatureType } from './selectTypes';

type FeatureCompare = (a: Feature<any>[], b: Feature<any>[]) => boolean;
type ValueCompare = <T>(a: T[], b: Feature<T>[]) => boolean;
type FeatureArrayReduce = <T>(f: Feature<T>[]) => any;

export const simpleFeatureCompare: FeatureCompare = <T>(a: Feature<T>[], b: Feature<T>[]): boolean => {
  return a.map((v) => v.value).some((av) => b.map((v) => v.value).includes(av));
};
export const multipleOptionFeatureCompare: FeatureCompare = <T>(a: Feature<T[]>[], b: Feature<T[]>[]): boolean => {
  const aList = a.map((v) => v.value);
  const bList = b.map((v) => v.value);

  return aList.some((av) => (av ? bList.some((bv) => (bv ? av.every((ae) => bv.includes(ae)) : false)) : false));
};

export const simpleValueCompare: ValueCompare = <T>(a: T[], b: Feature<T>[]): boolean => {
  return a.some((av) => b.map((v) => v.value).includes(av));
};

// should be applied to features that should be unique
// groups: type, lasting,silhouette
// appearance: sort
// flower: petal_shape, flower_shape, inflorescence
// foliage: leaf_shape
export const mostFrequentReduce: FeatureArrayReduce = <T>(featureArray: Feature<T>[]): T | null => {
  if (!featureArray || featureArray.length === 0) {
    return null;
  }
  const reduced = featureArray.reduce((a, b, i, arr) =>
    arr.filter((v) => v.value === a.value).length >= arr.filter((v) => v.value === b.value).length ? a : b
  );

  return reduced.value || null;
};

// should be applied only to features that have arrayed string values
// groups.use
// characteristics: decorativeness
// flower: color
// foliage: color
export const flattenReduce: FeatureArrayReduce = <T>(featureArray: Feature<T>[]): T | null => {
  if (!featureArray || featureArray.length === 0) {
    return null;
  }
  const valuesArray = featureArray.map((f: Feature<T>) => f.value);
  if (Array.isArray(featureArray[0].value)) {
    // hack to flatten array that Typescript does nnot know of
    const vals = JSON.parse('[' + JSON.stringify(valuesArray).replace(/\[/gi, '').replace(/\]/gi, '') + ']');
    return vals.filter((el: any, idx: number) => vals.indexOf(el) === idx);
  } else {
    // should never happen
    return featureArray.map((f: Feature<T>) => f.value)[0] || null;
  }
};

// should be applied to features that frequency is important
// appearance: max_height, max_width, cutting_ratio
// position: insolation, humidity, ph_name, soil
// characteristics: endure, fragile, care
// flower: time
// foliage: time
export const countedFrequencyReduce: FeatureArrayReduce = <T>(featureArray: Feature<T>[]): { value: T; count: number }[] => {
  if (!featureArray || featureArray.length === 0) {
    return [];
  }

  const valueMap: { [key: string]: { value: T; count: number } } = {};
  featureArray.forEach((f) => {
    const id = JSON.stringify(f.value);
    if (valueMap[id]) {
      valueMap[id].count++;
    } else {
      if (f.value) {
        valueMap[id] = { value: f.value, count: 1 };
      }
    }
  });
  return Object.values(valueMap);
};

// should be applied to features that reducing is not needed
// flower: pattern
// foliage: pattern
export const noReduce: FeatureArrayReduce = <T>(featureArray: Feature<T>[]): T[] => {
  if (!featureArray || featureArray.length === 0) {
    return [];
  }
  return featureArray.map((f: Feature<T>) => f.value).filter((f: T | undefined): f is T => !!f);
};

export interface FeatureConstraint {
  name: string;
  values: any[];
}

class DefaultConfig {
  name: string;
  constraints: FeatureConstraint[] = [];
  multipleValues = false;
  contributorRoles = ['admin'];

  featureCompare: FeatureCompare = simpleFeatureCompare;
  valueCompare: ValueCompare = simpleValueCompare;
  reduce: FeatureArrayReduce = mostFrequentReduce;

  constructor(name: string) {
    this.name = name;
  }

  renderValue: <T>(v: T) => string = (v: unknown) => String(v);
  renderName = () => `features.${this.name ? this.name : ''}.`;
}

export interface FeatureConfig extends DefaultConfig {
  controlType: 'option' | 'range' | 'color' | 'shape' | 'pattern';
  selectType: FeatureType;
  multiplePredefined?: any[];
}

// export const typeAssert = {
//   isOptionType(t:FeatureType): t is OptionType {
//     return notEmpty((t as OptionType).values)
//   },

//   isParametrizedOptionType(t:FeatureType): t is ParametrizedOptionType {
//     const type = t as ParametrizedOptionType;
//     return notEmpty(type.values && type.parameter)
//   },

//   isRangeType(t:FeatureType): t is RangeType {
//     return notEmpty((t as RangeType).max || (t as RangeType).min);
//   }
// }

export const defaultFeatureConfig = (name: string): DefaultConfig => new DefaultConfig(name);
