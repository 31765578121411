import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Feature, GENERAL_FEATURE_CONFIG as GFT } from 'src/app/types/features';

import { OptionType, ParametrizedOptionType } from 'src/app/types/features/selectTypes';
import { FeatureConfig } from 'src/app/types/features/config';
import { optionType } from 'src/app/types/features/types';

@Component({
  selector: 'app-option-select',
  templateUrl: './option-select.component.html',
  styleUrls: ['./option-select.component.scss']
})
export class OptionSelectComponent implements OnChanges {
  @Input() feature!: Feature<OptionType>;
  @Input() saved?: BehaviorSubject<boolean>;

  @Output() changed = new EventEmitter<Feature<OptionType>>();

  group?: string;
  name?: string;
  optionName?: string;

  featureConfig?: FeatureConfig;
  optionsConfig?: ParametrizedOptionType;

  options: any = {};
  // {
  //   'groups.type'?: plantType;
  //   'groups.lasting'?: lastingType;
  //   'groups.use'?: useType;
  //   'groups.silhouette'?: silhouetteType;
  //   'position.insolation'?: insolationType;
  //   'position.humidity'?: humidityType;
  //   'position.ph_name'?: phType;
  //   'position.soil'?: soilType;
  //   'position.frost-resistance'?: frostType;
  //   'appearance.growth_strength'?: growthType;
  //   'appearance.sort'?: sortType;
  //   'appearance.cutting_ratio'?: cuttingType;
  //   'flower.flower_shape'?: flowerShape;
  //   'flower.inflorescence'?: inflorescenceType;
  //   'characteristics.decorativeness'?: decorativeType;
  //   'characteristics.endure'?: endureType;
  //   'characteristics.fragile'?: fragileType;
  //   'characteristics.care'?: careType;
  // } = {};
  multiple = false;

  /** user has changed the value, and it is scheduled to save */
  modified = false;
  /** user had set the value previously */
  default = true;

  private initVal?: optionType[];
  private privateVal?: optionType[];
  get value(): optionType[] | undefined {
    return this.privateVal;
  }
  set value(newVal: optionType[] | undefined) {
    this.privateVal = newVal;
    const info = this.feature
      ? {
          featureId: this.feature.featureId,
          owner: this.feature.ownerId
        }
      : {};

    this.changed.emit({
      group: this.group,
      name: this.name,
      value: {
        renderValue: this.featureConfig?.renderValue,
        values: newVal,
        multiple: this.featureConfig?.multipleValues
      },
      ...info
    } as Feature<OptionType>);

    this.modified = true;
    this.default = false;
  }

  ngOnChanges(): void {
    this.group = this.feature.group;
    this.name = this.feature.name;
    this.optionName = this.group + '.' + this.name;
    this.featureConfig = GFT.get(this.group, this.name);

    this.optionsConfig = this.featureConfig.selectType as ParametrizedOptionType;

    this.options[this.optionName] = [...this.optionsConfig.values];
    if (this.optionsConfig.multiple) {
      this.multiple = true;
      this.privateVal = this.feature.value
        ? [...(Array.isArray(this.feature.value.values) ? this.feature.value.values : (this.feature.value as unknown as Array<any>))]
        : [];
    } else {
      this.privateVal = this.feature.value
        ? [...(Array.isArray(this.feature.value.values) ? this.feature.value.values : (this.feature.value as unknown as Array<any>))]
        : undefined;
    }

    this.initVal = this.privateVal;
    if (this.saved) {
      this.saved.subscribe((saved) => {
        if (this.modified) {
          this.modified = false;
          if (!saved) {
            this.privateVal = this.initVal;
          }
        }
      });
    }
  }

  toggleSelection(opt: optionType): void {
    if (this.optionsConfig?.parameter) {
      this.feature.min = 0;
    }
    if (Array.isArray(this.value) && this.value.includes(opt)) {
      this.value = this.value.filter((e: any) => e !== opt);
    } else if (this.value === undefined) {
      this.value = [opt];
    } else {
      this.value = [...this.value, opt]; // not push because we want to invoke set()
    }
  }
}
