import { APIFeature } from 'src/app/api/models/api-feature';
import { APIFeatureGroup } from 'src/app/api/models/api-feature-group';
import { APIFeatureName } from 'src/app/api/models/api-feature-name';
import { GENERAL_FEATURE_CONFIG as GFT } from './types';
export const GENERAL_FEATURE_CONFIG = GFT;

const parseJSON: <T>(value?: string) => T | undefined = <T>(value?: string) => {
  try {
    if (typeof value == 'string') {
      return JSON.parse(value) as T;
    }
    // if (typeof value == 'number') {
    //   return value as unknown as T;
    // }
    // if (typeof value == 'object') {
    //   return value as unknown as T;
    // }
  } catch (e) {
    return value as unknown as T;
  }
  return undefined;
};

export class Feature<T> {
  group = '';
  name = '';
  value?: T;
  featureId?: string;
  ownerId?: number;
  min?: number;
  max?: number;
  render?: (value: T) => string;

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(data: APIFeature, convert = parseJSON) {
    if (!data || data === null) {
      return;
    }

    // let group = data.group;
    // let name = data.name;
    // if (data.groupName) {
    //   [group, name] = data.groupName.split('.');
    // }
    this.group = data.group;
    this.name = data.name;
    this.featureId = data.featureId;
    this.ownerId = data.ownerId;

    this.min = data.min;
    this.max = data.max;

    this.value = data.value ? convert(data.value) : undefined;
    this.render = (val) => {
      const feature = GFT.get(data.group, data.name);
      return feature.renderValue<T>(val);
    };
  }

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public static create<T>(
    group: APIFeatureGroup,
    name: APIFeatureName,
    value?: string,
    ownerId?: number,
    convert: <T>(value?: string) => T | undefined = parseJSON
  ): Feature<T> {
    //const config = GFT.get(group, name);
    return new Feature(
      {
        group: group,
        name,
        featureId: Math.random().toString(36).substring(2, 15) + '-raw',
        ownerId,
        value
      },
      convert
    );
  }
}

export class Features {
  list: { [key: string]: Feature<any>[] } = {};
  get length(): number {
    return Object.keys(this.list).length;
  }

  // TEMP till moving API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(data: Feature<any>[] | Features) {
    if (Array.isArray(data)) {
      data.forEach((newFeature: Feature<any>) => {
        const key = `${newFeature.group}.${newFeature.name}`;
        if (!Object.keys(this.list).includes(key)) {
          this.list[key] = [];
        }
        this.list[key].push(newFeature);
      });
    } else {
      this.list = { ...data.list };
    }
  }

  // public static isArray(name: string): boolean {
  //   return !['type', 'lasting', 'care'].includes(name);
  // }
  public static getGroups(): string[] {
    return GFT.getGroups();
  }
  public static getFeatureNames(group: string): string[] {
    // should not be possible to have group without subgroup
    // return typeof GENERAL_FEATURE_CONFIG[group] === 'object' ? Object.keys(GENERAL_FEATURE_CONFIG[group]) : GENERAL_FEATURE_CONFIG[group];
    return GFT.getGroupNames(group);
  }

  setFeature(value: Feature<any>[], group: string, name?: string): void {
    if (name !== undefined) {
      this.list[group + '.' + name] = value;
    } else {
      this.list[group] = value;
    }
  }

  remove(feature: Feature<any>): this {
    this.list[feature.group + '.' + feature.name] = this.list[feature.group + '.' + feature.name].filter(
      (f) => f.featureId === feature.featureId
    );
    return this;
  }

  filterUser(userId: number): Features {
    const features = new Features([]);
    Object.keys(this.list).forEach((key) => {
      const keyFeatures = this.list[key];

      const ownedFeatures = keyFeatures.filter((e) => e.ownerId === userId);
      if (ownedFeatures.length > 0) {
        const { group, name } = ownedFeatures[0];

        features.setFeature(ownedFeatures, group, name);
      }
    });
    return features;
  }

  public getReducedValues(groupKey: string): any {
    const featureList = this.list[groupKey];
    const reduce = GFT.get(groupKey).reduce;
    return reduce(featureList);
  }

  public get(groupKey: string): Feature<any>[] {
    return this.list[groupKey];
  }
  public getFiltered<T>(groupKey: string, filter: (list: Feature<any>[]) => T, notFoundValue: T): T {
    const featureList = this.list[groupKey];
    return featureList ? filter(featureList) : notFoundValue;
  }
  public getGroupFeatures(group: string): { [key: string]: Feature<any>[] } {
    return Object.keys(this.list)
      .filter((key) => key.includes(group + '.'))
      .reduce((obj: { [key: string]: Feature<any>[] }, curKey) => {
        obj[curKey] = this.list[curKey];
        return obj;
      }, {});
  }

  public queryfy(): any {
    const result: { [key: string]: string } = {};

    Object.keys(this.list)
      .filter((key) => this.list[key].length > 0 && this.list[key][0].value && Object.keys(this.list[key][0].value).length > 0)
      .forEach((key) => {
        result[key] = JSON.stringify(this.list[key][0].value);
      });

    return result;
  }
}
