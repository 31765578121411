<!-- <input type="file" id="file" (change)="onSelect($event.target)" /> -->

<section>
  <input hidden id="file" type="file" #uploader accept="image/*" (change)="onSelect($event.target)" (click)="uploader.value = ''" />
  <mat-card *ngIf="!file">
    <div (click)="uploader.click()" class="image">
      <div *ngIf="!loading" class="placeholder">
        <mat-icon>photo</mat-icon>
        <p>{{ 'buckets.mobile.add' | translate }}</p>
      </div>
      <mat-spinner *ngIf="loading" [diameter]="50"></mat-spinner>
    </div>
  </mat-card>
</section>

<ng-container *ngIf="user$ | async; let user">
  <ng-container *ngIf="file">
    <mat-icon
      *ngIf="!this.imageToCrop"
      (click)="imageToCrop = originalFile"
      class="crop"
      [matTooltip]="'forms.image.crop-icon' | translate"
    >
      crop
    </mat-icon>
    <img *ngIf="!imageToCrop?.data" #img [src]="file.file.data" />

    <app-cropper
      *ngIf="imageToCrop?.data"
      [resizeToWidth]="1500"
      [image]="imageToCrop?.data || ''"
      [maintainAspectRatio]="true"
      [aspectRatio]="16 / 9"
      (closed)="imageToCrop = undefined"
      (croppedImage)="setCropped($event)"
    ></app-cropper>

    <app-upload-task
      *ngIf="file.uploading"
      [fileName]="file.file.name"
      [src]="file.file.data"
      [thumb]="file.file.thumb"
      [bucket]="'buckets/' + user.userId"
      [withMiniature]="true"
      (finished)="finishUpload($event, file)"
    ></app-upload-task>

    <app-action-button *ngIf="!this.imageToCrop" type="accept" variant="filled" (click)="onSubmit()">
      {{ 'buttons.image.save' | translate }}
    </app-action-button>
  </ng-container>
</ng-container>
