import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'src/app/guards/auth.guard';
import { getDefaultLang } from 'src/app/helpers/lang';
import { AppUserComponent } from './app.component';

const routes: Routes = [
  { path: '', redirectTo: '/' + getDefaultLang(), pathMatch: 'full' },
  {
    path: ':lang',
    component: AppUserComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule)
      },
      {
        path: 'identify',
        loadChildren: () => import('./pages/identify/identify.module').then((m) => m.IdentifyPageModule)
      },
      // TODO PHASE 2
      // {
      //   path: 'notifications',
      //   loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsPageModule)
      // },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('./pages/payment/payment.module').then((m) => m.PaymentPageModule)
      },
      {
        path: '404',
        loadChildren: () => import('src/app/pages/not-found/not-found.module').then((m) => m.NotFoundPageModule)
      },
      {
        path: '401',
        loadChildren: () => import('src/app/pages/not-authorized/not-authorized.module').then((m) => m.NotAuthorizedPageModule)
      }
    ]
  },
  { path: '**', redirectTo: '/' + getDefaultLang() + '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
