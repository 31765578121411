import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { LayoutModule } from '@angular/cdk/layout';

import { TranslateModule } from '@ngx-translate/core';

import { LightboxModule } from 'ngx-lightbox';
// import { Ng5SliderModule } from 'ng5-slider';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChartsModule } from 'ng2-charts';

import { CustomMaterialModule } from 'src/app/components/custom.material.module';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { DirectivesModule } from 'src/app/directives/directives.module';

import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { AddButtonComponent } from './add-button/add-button.component';
import { DoughnutChartComponent } from '../pages/home/components/doughnut-chart/doughnut-chart.component';
import { ImageTaxonsComponent } from './image-taxons/image-taxons.component';
import { PlantNameComponent } from './plant-name/plant-name.component';
import { EmptyComponent } from './empty/empty.component';
import { HomeHeaderEditComponent } from './header-edit/header-edit.component';
import { HomeHeaderComponent } from './header/header.component';
import { ShopIconComponent } from './shop-icon/shop-icon.component';
import { AddPlantIconComponent } from './add-plant-icon/add-plant-icon.component';
import { AddPhotoIconComponent } from './add-photo-icon/add-photo-icon.component';

@NgModule({
  declarations: [
    NavigationBarComponent,
    AddButtonComponent,
    DoughnutChartComponent,
    ImageTaxonsComponent,
    PlantNameComponent,
    EmptyComponent,
    HomeHeaderComponent,
    HomeHeaderEditComponent,
    ShopIconComponent,
    AddPlantIconComponent,
    AddPhotoIconComponent
  ],
  exports: [
    NavigationBarComponent,
    AddButtonComponent,
    DoughnutChartComponent,
    ImageTaxonsComponent,
    PlantNameComponent,
    EmptyComponent,
    HomeHeaderComponent,
    HomeHeaderEditComponent,
    ShopIconComponent,
    AddPlantIconComponent,
    AddPhotoIconComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ComponentsModule,
    CustomMaterialModule,
    RouterModule,
    TranslateModule.forChild(),
    ImageCropperModule,
    PipeModule,
    DirectivesModule,
    LightboxModule,
    // Ng5SliderModule,
    GoogleMapsModule,
    LayoutModule,
    ChartsModule
  ]
})
export class UserComponentsModule {}
